<template>
    <div>
        <page-title>{{ $t('labels.registration_codes') }}</page-title>
        <registration-codes-table
            :selected-filters.sync="selectedFilters"
            :selected-items.sync="selectedItems"
        >
            <template v-slot:actions>
                <create-button
                    :route-name="routeType.REGISTRATION_CODES_CREATE"
                ></create-button>
            </template>
        </registration-codes-table>
    </div>
</template>

<script>
import routeType from '@/router/routeType'
import PageTitle from '@/components/layout/components/pageTitle'
import RegistrationCodesTable from '@/components/pages/registrationCodes/Table'
import CreateButton from '@/components/form/Actions/CreateButton'

export default {
    components: {
        CreateButton,
        PageTitle,
        RegistrationCodesTable,
    },
    data() {
        return {
            routeType,
            selectedFilters: {},
            selectedItems: [],
        }
    },
}
</script>
