var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('data-iterator',{ref:"iterator",attrs:{"api":_vm.api,"filters":_vm.filters},on:{"update:filters":[function($event){_vm.filters=$event},_vm.updateFilters]},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_vm._t("actions")]},proxy:true},{key:"default",fn:function(ref){
var items = ref.items;
return [_c('d-table',{attrs:{"items":items,"headers":_vm.headers,"row-action":""},scopedSlots:_vm._u([{key:"item.company",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":_vm.getEditLink(item)}},[_vm._v(" "+_vm._s(_vm.companyName(item.company))+" ")])]}},{key:"item.from",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$date(item.validFrom).format('YYYY-MM-DD HH:mm'))+" ")]}},{key:"item.to",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$date(item.validTo).format('YYYY-MM-DD HH:mm'))+" ")]}},{key:"rowActions",fn:function(ref){
var item = ref.item;
return [_c('edit-button',{attrs:{"to":_vm.getEditLink(item)}}),_c('delete-action',{attrs:{"entity-name":item.code,"entity-id":item.id,"api":_vm.api},on:{"deleted":_vm.onEntityDeleted,"failed":_vm.onEntityDeleteFailed}})]}}],null,true)})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }