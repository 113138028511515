<template>
    <data-iterator
        ref="iterator"
        :api="api"
        :filters.sync="filters"
        @update:filters="updateFilters"
    >
        <template v-slot:actions>
            <slot name="actions"></slot>
        </template>

        <template slot-scope="{ items }">
            <d-table :items="items" :headers="headers" row-action>
                <!-- name column -->
                <template v-slot:[`item.company`]="{ item }">
                    <router-link :to="getEditLink(item)">
                        {{ companyName(item.company) }}
                    </router-link>
                </template>

                <template v-slot:[`item.from`]="{ item }">
                    {{ $date(item.validFrom).format('YYYY-MM-DD HH:mm') }}
                </template>

                <template v-slot:[`item.to`]="{ item }">
                    {{ $date(item.validTo).format('YYYY-MM-DD HH:mm') }}
                </template>

                <!-- row actions -->
                <template v-slot:rowActions="{ item }">
                    <edit-button :to="getEditLink(item)"></edit-button>
                    <delete-action
                        :entity-name="item.code"
                        :entity-id="item.id"
                        :api="api"
                        @deleted="onEntityDeleted"
                        @failed="onEntityDeleteFailed"
                    ></delete-action>
                </template>
            </d-table>
        </template>
    </data-iterator>
</template>

<script>
import RestApiType from '@/api/RestApiType'
import routeType from '@/router/routeType'
import selectapiType from '@/store/type/selectapiType'
import DataIteratorMixin from '@/components/mixins/DataIteratorMixin'
import DataTableMixin from '@/components/mixins/DataTableMixin'
import Headers from '@/pages/registrationCodes/mixins/Headers'
import IdFromIri from '@/services/IdFromIri'

export default {
    mixins: [DataIteratorMixin, DataTableMixin, Headers],
    data() {
        return {
            api: RestApiType.REGISTRATION_CODES,
            companyNames: [],
        }
    },
    computed: {
        tableName() {
            return this.$t('labels.registration_codes')
        },
    },
    mounted() {
        this.$store
            .dispatch(
                selectapiType.getActionName(
                    selectapiType.clientTypes.COMPANY,
                    selectapiType.actions.LOAD
                )
            )
            .then((data) => (this.companyNames = data))
    },
    methods: {
        getEditLink(item) {
            return {
                name: routeType.REGISTRATION_CODES_EDIT,
                params: { id: item.id },
            }
        },
        companyName(iri) {
            const id = parseInt(IdFromIri.getId(iri))
            return this.companyNames.find((val) => val.id === id)?.name
        },
    },
}
</script>
