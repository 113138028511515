export default {
    computed: {
        headers() {
            return [
                {
                    value: 'company',
                    text: this.$t('labels.company'),
                    type: 'string',
                },
                {
                    value: 'from',
                    text: this.$t('labels.from'),
                    type: 'string',
                },
                {
                    value: 'to',
                    text: this.$t('labels.to'),
                    type: 'string',
                },
                {
                    value: 'code',
                    text: this.$t('labels.code'),
                    type: 'string',
                },
            ]
        },
    },
}
